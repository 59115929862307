<template>
  <div>
    <div
      class="setting-colr rounded mb-1"
      @click="show = !show"
    >
      <p class="mb-0">
        Mini promotion
      </p>

      <i class="fas fa-plus-circle" />
    </div>

    <div
      v-if="show"
      class="clrr-setting"
    >
      <ul class="nav-menu-wrapper mr-4">
        <li class="nav-item ng-star-inserted d-flex justify-content-around align-items-center">
          <b-form-checkbox
            v-model="wheel_status"
            class="custom-control-success"
            name="check-button"
            :value="1"
            :unchecked-value="0"
            switch
            @change="changeWheel(wheel_status)"
          />
          <div class="-img-wrapper">
            <img
              src="@/assets/newImg/wheel.png"
              alt="Menu icon promotion"
              width="30"
              height="30"
              class="-icon"
            >
          </div>
          <div class="-text">
            หมุนกงล้อ
          </div>
        </li>

        <li class="nav-item ng-star-inserted d-flex justify-content-around align-items-center">
          <b-form-checkbox
            v-model="fixed_deposit_status"
            class="custom-control-success"
            name="check-button"
            :value="1"
            :unchecked-value="0"
            switch
            @change="changeFixed(fixed_deposit_status)"
          />
          <div class="-img-wrapper">
            <img
              src="@/assets/newImg/depo.png"
              alt="Menu icon promotion"
              width="30"
              height="30"
              class="-icon"
            >
          </div>
          <div class="-text">
            ฝากประจำ
          </div>
        </li>
        <li class="nav-item ng-star-inserted d-flex justify-content-around align-items-center">
          <b-form-checkbox
            v-model="invite_friends_status"
            class="custom-control-success"
            name="check-button"
            :value="1"
            :unchecked-value="0"
            switch
            @change="changeInvite(invite_friends_status)"
          />
          <div class="-img-wrapper">
            <img
              src="@/assets/newImg/affiliate.png"
              alt="Menu icon promotion"
              width="30"
              height="30"
              class="-icon"
            >
          </div>
          <div class="-text">
            สร้างรายได้
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ColorPicker from 'vue2-color-picker-gradient'
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  components: {
    ColorPicker,
    BFormCheckbox,
  },
  props: ['wheel_status', 'fixed_deposit_status', 'invite_friends_status'],
  data: () => ({
    show: false,
    titleGradient: {
      show: true,
      text: 'เลือกสีหัวข้อของทุกหน้า',
    },
    pColor: {
      hsl: {
        h: 150, s: 0.5, l: 0.2, a: 1,
      },
      hsv: {
        h: 150, s: 0.66, v: 0.3, a: 1,
      },
      rgba: {
        r: 25, g: 77, b: 51, a: 1,
      },
      a: 1,
      color: 'rgba(0,0,0,1)',
    },
  }),
  mounted() {
  },
  methods: {
    async changeWheel(event) {
      this.$emit('setWheel', event)
    },
    async changeFixed(event) {
      this.$emit('setFixed', event)
    },
    async changeInvite(event) {
      this.$emit('setInvite', event)
    },
  },
}
</script>
