<template>
  <div>
    <div
      class="setting-colr rounded mb-1"
      @click="show = !show"
    >
      <p class="mb-0">
        พื้นหลัง <small>(1920 * 1080px)</small>
      </p>

      <i class="fas fa-plus-circle" />
    </div>

    <div
      v-if="show"
      class="clrr-setting"
    >
      <div class="input-file-container">
        <i class="fal fa-plus cursor-pointer" />
        <label
          for="img-file"
          class="input-file-label"
        >{{
          bg ? "เปลี่ยนรูปภาพ" : "เพิ่มรูปภาพ"
        }}</label>
        <input
          id="img-file"
          type="file"
          class="input-file"
          accept="image/*"
          @change="handleFileChange"
        >
      </div>

      <div
        v-if="bg"
        class="image-preview"
      >
        <img
          :src="bg"
          alt="Preview"
          class="preview-image"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['bg'],
  data: () => ({
    show: false,
    agent: null,
  }),
  mounted() {
  },
  methods: {
    async handleFileChange(event) {
      const base64 = await this.getBase64Image(event.target.files[0])
      this.$emit('setData', base64)
    },
    getBase64Image(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = event => {
          resolve(event.target.result)
        }
        reader.onerror = error => {
          reject(error)
        }
        reader.readAsDataURL(file)
      })
    },
  },
}
</script>

<!-- <template>
  <div>
    <div
      class="setting-colr rounded mb-1"
      @click="show = !show"
    >
      <p class="mb-0">
        สีพื้นหลัง
      </p>

      <i class="fas fa-plus-circle" />
    </div>

    <div
      v-if="show"
      class="clrr-setting"
    >
      <div class="color_poick">
        <ColorPicker
          :pColor="color || pColor"
          :title-config="titleGradient"
          :close-on-click-body="false"
          @changeColor="changeColor"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from 'vue2-color-picker-gradient'
export default {
  components: {
    ColorPicker,
  },
  props: ['color'],
  data: () => ({
    show: false,
    titleGradient: {
      show: true,
      text: 'เลือกสีพื้นหลัง',
    },
    pColor: {
      hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
      hsv: { h: 150, s: 0.66, v: 0.3, a: 1 },
      rgba: { r: 25, g: 77, b: 51, a: 1 },
      a: 1,
      color: "rgba(0,0,0,1)",
    },
  }),
  mounted() {
  },
  methods: {
    async changeColor(event) {
      this.$emit('setData', event.color)
    }
  }
};
</script> -->
